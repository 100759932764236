<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn
            v-if="orders.length > 0"
            elevation="9"
            rounded
            color="grey"
            @click="$router.go(-1)"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div class="row ">
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Billing Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                    <p class="mb-2">
                      {{ billto.company_name }}<br />
                      {{ billto.address_line1 }}<br />
                      {{ billto.address_line2 }}<br />
                      {{ billto.city }} {{ billto.state }} {{ billto.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      (123) 456-7890
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Shipping Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                    <p class="mb-2">
                      {{ shipto.company_name }}<br />
                      {{ shipto.address_line1 }}<br />
                      {{ shipto.address_line2 }}<br />
                      {{ shipto.city }} {{ shipto.state }} {{ shipto.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      (123) 456-7890
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->

              <div class="col-lg-4">
                <v-card flat>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <p v-if="orders.length > 0" class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Master Order #:</span
                        >
                        {{ orders[0].main_order_id }}
                      </p>
                      <p v-if="orders.length > 0" class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Vendor Order #:</span
                        >
                        {{ orders[0].order_id }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Purchase Order #:</span
                        >
                        {{ orders[0].po_number }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Invoice #:</span
                        >
                        {{ $route.params.id }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Payment Type:</span
                        >
                        NET TERMS
                      </p>
                    </li>
                  </ul>
                </v-card>
              </div>
              <!-- end col -->
            </div>

            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">Invoice Details</h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Line ID</th>
                          <th style="width:280px;">SKU</th>
                          <th>Thumb</th>
                          <th style="width:280px;">Item</th>
                          <th style="width:100px;">Qty Invoiced</th>
                          <th class="text-left">Price</th>
                          <th class="text-left">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in orders" :key="idx">
                          <td>{{ idx + 1 }}</td>
                          <td>{{ item.sku_id }}</td>
                          <td>
                            <v-img
                              class="d-flex align-self-center mr-3 rounded-circle"
                              :src="
                                item.url != null
                                  ? baseurl + item.url
                                  : '/media/rollerskate.png'
                              "
                              alt="Generic placeholder image"
                              width="50"
                              height="50"
                            >
                            </v-img>
                          </td>
                          <td>
                            {{ item.product_name }}
                            <span v-if="item.item_name != 'default'"
                              >({{ item.item_name }})</span
                            >
                          </td>
                          <td>
                            {{ item.qty_to_invoice }}
                          </td>
                          <td>{{ item.price | dollar_format }}</td>
                          <td>
                            {{
                              (item.price * item.qty_to_invoice) | dollar_format
                            }}
                          </td>
                        </tr>
                        <tr v-if="orders.length > 0">
                          <td colspan="6" style="text-align: right;">
                            Subtotal
                          </td>
                          <td>{{ orders[0].subtotal | dollar_format }}</td>
                        </tr>
                        <tr>
                          <td colspan="6" style="text-align: right;">
                            Shipping amount
                          </td>
                          <td>
                            {{ orders[0].shipping_amount | dollar_format }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6" style="text-align: right;">Total</td>
                          <td>{{ orders[0].total | dollar_format }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <!--end: Datatable-->
                  </div>
                </v-card>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    dialog: false,
    orderTab: "Information",
    selected: 2,
    model: "tab-2",
    orders: [],
    main_order_date: "",
    billto: [],
    shipto: [],
  }),
  apollo: {
    // invoice_items: {
    //   client: "ecomClient",
    //   query: gql`
    //     query InvoiceItems($invoice_id: Int) {
    //       invoice_items(invoice_id: $invoice_id) {
    //         id
    //         supplier
    //         price
    //         qty
    //         qty_invoiced
    //         qty_to_invoice
    //         item_id
    //         product_id
    //         product_name
    //         item_name
    //         sku_id
    //         supplier_name
    //         url
    //         order_id
    //         status
    //         subtotal
    //         shipping_amount
    //         total
    //         created_at
    //         main_order_id
    //         po_number
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       invoice_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
  },

  created() {
    this.getVendorInvoiceDetail();
  },
  methods: {
    subTotal(items) {
      let sum = 0;
      if (items.length > 0) {
        for (let i in items) {
          sum += items[i].qty_to_return * items[i].price;
        }
      }
      return sum;
    },
    isInt(n) {
      return n % 1 === 0;
    },
    async getVendorInvoiceDetail() {
      await this.$apollo.queries.invoice_items.refetch();
      let order_detail = this.invoice_items;
      if (order_detail.length > 0) {
        this.main_order_date = order_detail[0].created_at;
        //get order address
        // let ares = await this.$apollo.query({
        //   client: "ecomClient",
        //   query: gql`
        //     query OrderAddress($main_order_id: Int) {
        //       order_address: order_address(main_order_id: $main_order_id) {
        //         id
        //         main_order_id
        //         address_line1
        //         address_line2
        //         city
        //         state
        //         zip_code
        //         address_type
        //         company_name
        //         first_name
        //         last_name
        //       }
        //     }
        //   `,
        //   variables: {
        //     main_order_id: parseInt(order_detail[0].main_order_id),
        //   },
        // });
        // let address_arr = ares.data.order_address;
        // for (let i in address_arr) {
        //   if (address_arr[i].address_type == "billto") {
        //     this.billto = address_arr[i];
        //   } else {
        //     this.shipto = address_arr[i];
        //   }
        // }
      }
      this.orders = order_detail;
    },
  },
};
</script>
